import React from "react";
import { Link } from "react-router-dom";

const Rooms = () => {
  return (
    <div className="max-w-[1400px] h-[500px] bg-cyan-700 mx-auto my-20 pt-16 lg:mb-[20%] md:mb-[35%] px-4 grid lg:grid-cols-3 gap-4 text-white ">
      <div className="lg:top-20 relative lg:col-span-1 col-span-2">
        <h3 className="text-2xl font-bold">Solving your staffing issues</h3>
        <p className="pt-4">
          We are a temporary staffing agency that specializes in providing
          professional, qualified and trained staffing needs for in-home,
          community and healthcare. We provide services to children and youths
          with behavioural challenges, seniors with special needs and clients
          with acquired brain injury, dual diagnosis, autism spectrum disorder
          and other developmental disabilities.
        </p>

        <button className="bg-black text-white border-black hover:shadow-xl mt-4">
          <Link to="staffing">Learn More</Link>
        </button>
      </div>

      <div className="grid grid-cols-2 col-span-2 gap-2">
        <img
          className="object-cover w-full h-full"
          src="https://images.pexels.com/photos/6197117/pexels-photo-6197117.jpeg?auto=compress&cs=tinysrgb&w=1200"
          alt="/"
        />
        <img
          className="row-span-2 object-cover w-full h-full"
          src="https://images.pexels.com/photos/6197108/pexels-photo-6197108.jpeg?auto=compress&cs=tinysrgb&w=1200"
          alt="/"
        />
        <img
          className="object-cover w-full h-full"
          src="https://images.pexels.com/photos/1181438/pexels-photo-1181438.jpeg?auto=compress&cs=tinysrgb&w=1200"
          alt="/"
        />
      </div>
    </div>
  );
};

export default Rooms;
