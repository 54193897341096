import React from "react";

const MoreServices = () => {
  return (
    <div className="p-2 mt-4 grid md:grid-cols-2 mx-4">
      <div>
        <h2 className="text-2xl font-bold">Personal Support Workers</h2>
        <p className="mt-4 leading-7">
          Our dedicated team of personal support workers is committed to
          providing compassionate care to individuals and their families,
          whether in the community, at home, or in long-term care facilities and
          institutions. Our professionals excel in assisting with daily tasks,
          personal care and hygiene, as well as engaging in
          restorative/activation activities and efficient home management. While
          our personal attendants and support workers are trained to exercise
          their best judgment in addressing the needs of their clients, it's
          important to note that their role does not involve diagnosing,
          assessing, or responding to acute or unexpected situations beyond
          providing necessary emergency assistance when required.
        </p>
      </div>
      <div className="m-4">
        <img
          className="object-cover w-full h-full rounded-lg"
          src="https://images.pexels.com/photos/3791665/pexels-photo-3791665.jpeg?auto=compress&cs=tinysrgb&w=1200"
          alt=""
        />
      </div>
    </div>
  );
};

export default MoreServices;
