import React from "react";
import Footer from "../components/Footer";
import { MdHealthAndSafety } from "react-icons/md";
import { GiHealthPotion } from "react-icons/gi";
import { LuHeartHandshake } from "react-icons/lu";

const AboutPage = () => {
  return (
    <div className="">
      <div className="p-8 grid grid-cols-1 sm:grid-cols-2 gap-2">
        {/* create a banner black in color at the top */}
        <div className="pt-8 ">
          <h4 className="text-2xl font-bold">About us</h4>
          <p className="text-base text-gray-500">
            Located in Canada Super Support Services ( Tripple S) is a home to
            highly skilled and experienced support staff. We offer excellent
            standards and client focused services to all our clients
            indiscriminately. With our well trained staff we are committed to
            delivering the most effective and most compassionate support
            services to all our clients.
          </p>
          <h4 className="text-2xl font-bold mt-5">History</h4>
          <p className="text-base text-gray-500">
            Super Support Services SSS ( Tripple S) emerged as a sanctuary of
            healing and support within the healthcare realm. Our inception was
            inspired by a deep-seated commitment to redefine the wellness
            experience, creating a haven for those seeking transformative care.
            From our modest beginnings, we embarked on a journey dedicated to
            surpassing traditional standards and elevating the healthcare
            service landscape.
            <br />
          </p>
          {/* <p className="text-base text-gray-500 mt-2">
            At the heart of our mission lies an unwavering dedication to
            compassion, and excellence. Early on, our efforts were recognized
            with a prestigious certificate, acknowledging our commitment to
            providing top-tier healthcare services and pioneering advancements
            in patient care. These initial achievements paved the way for our
            metamorphosis into a cherished home-care partner, fostering trust
            and well-being.
          </p> */}
          <h4 className="text-2xl font-bold mt-5">Why choose us</h4>
          <p className="text-base text-gray-500"></p>
          <ul className="text-gray-500 list-disc ml-4">
            <li>Our Big Heart acknowledges everyone is special.</li>
            <li>
              Affordable support services-we ensure affordable support services
              closer to the people.
            </li>
            <li>
              Dedication-we are committed to delivering the most effective and
              most compassionate care to all our clients.
            </li>
          </ul>
        </div>
        <div className="pt-8 p-2">
          <img
            className="object-cover w-full h-full mt-3 rounded-lg "
            src="https://images.pexels.com/photos/7551675/pexels-photo-7551675.jpeg?auto=compress&cs=tinysrgb&w=1200"
            alt=""
          />
        </div>
      </div>
      {/* three cards section */}
      <h4 className="text-center px-8 pb-2 text-4xl font-bold">
        Our Core Values
      </h4>
      <div className="px-8 grid grid-col-1 gap-4 sm:grid-cols-3 text-white">
        <div className="bg-cyan-700    flex flex-col items-center rounded-lg  shadow-lg p-4 ">
          <span className="text-center">
            <MdHealthAndSafety
              className="text-10xl text-red-500 mt-4"
              size={85}
            />
          </span>
          <h2 className="font-bold">Client first</h2>
          <p className="text-center ">
            In an ever-evolving world, your well-being is our top priority. We
            adhere to the highest standards of health and safety protocols to
            create an environment where you can feel secure ,cared for and offer
            offer a personalized experience .
          </p>
        </div>
        <div className="bg-cyan-700   flex flex-col items-center rounded-lg  shadow-lg p-4 ">
          <span className="text-center">
            <LuHeartHandshake
              className="text-10xl text-red-500 mt-4"
              size={85}
            />
          </span>
          <h2 className="font-bold">Quality in service delivery</h2>
          <p className="text-center ">
            We believe in the power of teamwork and collaboration. By fostering
            partnerships among healthcare professionals, we deliver
            comprehensive care for our clients.
          </p>
        </div>
        <div className="bg-cyan-700    flex flex-col items-center rounded-lg  shadow-lg p-4 ">
          <span className="text-center">
            <GiHealthPotion className="text-10xl text-red-500 mt-4" size={85} />
          </span>
          <h2 className="font-bold">Diversity</h2>
          <p className="text-center text-white mt-4 mb-6">
            We value and respect the differences in our clients and their
            families who seek our care as well as those who provide it.
          </p>
        </div>
      </div>
      {/* team section */}
      <Footer />
    </div>
  );
};

export default AboutPage;
