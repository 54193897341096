import React from "react";
import Footer from "../components/Footer";

const Staffing = () => {
  return (
    <div className="h-screen">
      <div className="relative h-[300px] w-full ">
        <img
          className="top-0 left-0 w-full h-full object-cover "
          src="https://images.pexels.com/photos/6197108/pexels-photo-6197108.jpeg?auto=compress&cs=tinysrgb&w=1200"
          alt="/"
        />
        <div
          className=" before:content-['']
        block
          before:absolute
          before:inset-0
          before:block
          before:bg-gradient-to-r
          before:from-cyan-700
          before:to-cyan-300
          before:opacity-75
          before:z-[-5] absolute top-0 left-0 w-full h-full opacity-40"
        />
        <div className=" absolute top-0 left-0 w-full h-full  flex items-center justify-center text-white">
          <h4 className="font-bold text-4xl">Staffing Services</h4>
        </div>
      </div>
      <div className="p-2 mt-4 grid md:grid-cols-2 ">
        <div className="mb-4">
          <h4 className="font-bold text-3xl m-4">
            Our Staffing solutions Entails
          </h4>
          <p className="text-base m-4 text-gray-500">
            Super Support Services extends its comprehensive array of services
            to encompass staffing within the comfort of your own home.Our
            offerings go beyond the traditional, as we specialize in providing a
            range of personalized services to cater to your unique needs. our
            team is dedicated to ensuring a holistic and supportive environment
            for individuals in need.
          </p>
          <p className="text-base m-4 text-gray-500">
            We understand the significance of a clean and organized living
            space, and our trained professionals go the extra mile to ensure
            homes are not only cared for medically but also maintained with the
            utmost cleanliness and attention to detail.{" "}
          </p>
          {/* <div className="bg-cyan-600 lg:mt-[40px] m-4 flex rounded-lg p-4 text-white shadow-xl ">
            <div className="border-green-300 flex-1 flex-row">
              <span className="font-bold text-2xl text-yellow-300"> 1000+</span>
              <h3 className="font-bold text-3xl">Happy Clients</h3>
            </div>
            <div className="flex-1">
              <span className="text-3xl font-extrabold">10</span>
              <h4 className="font-extrabold text-2xl">Years experience</h4>
            </div>
          </div> */}
        </div>
        <div className="sm:mt-2 m-4">
          <img
            className="object-cover w-full h-full rounded-lg"
            src="https://images.pexels.com/photos/9462341/pexels-photo-9462341.jpeg?auto=compress&cs=tinysrgb&w=1200"
            alt=""
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Staffing;
