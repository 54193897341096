import { RxHome, RxPerson, RxDashboard } from "react-icons/rx";

import { FaHouse } from "react-icons/fa6";
import { IoIosGitNetwork } from "react-icons/io";
import { LiaConnectdevelop } from "react-icons/lia";
import { PiBaby } from "react-icons/pi";
import { FaPersonShelter } from "react-icons/fa6";
export const Socials = [
  {
    name: "Discord",
    src: "/assets/discord.svg",
  },
  {
    name: "Facebook",
    src: "/assets/facebook.svg",
  },
  {
    name: "Instagram",
    src: "/assets/instagram.svg",
  },
];

export const NavLinks = [
  {
    name: "Home",
    icon: RxHome,
    link: "/",
  },
  {
    name: "About me",
    icon: RxPerson,
    link: "/about-me",
  },
  {
    name: "Projects",
    icon: RxDashboard,
    link: "/my-projects",
  },
];

export const ProImages = [
  {
    src: "/assets/projects/SpaceCity.jpg",
  },
  {
    src: "/assets/projects/SpaceCity1.jpg",
  },
  {
    src: "/assets/projects/SpaceCity2.jpg",
  },
  {
    src: "/assets/projects/SpaceCity3.jpg",
  },
];

export const ProImages2 = [
  {
    src: "/assets/projects/Orange1.jpeg",
  },
  {
    src: "/assets/projects/Orange2.jpeg",
  },
  {
    src: "/assets/projects/Orange3.jpeg",
  },
  {
    src: "/assets/projects/Orange4.jpeg",
  },
];

export const ServiceData = [
  {
    icon: FaHouse,
    title: "Hourly Care Services",
    content:
      "Our hourly care services offer flexibility and personalized attention, ensuring that our clients receive the support they require, precisely when they need it.",
    backgroundImage:
      "https://images.pexels.com/photos/4107099/pexels-photo-4107099.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    icon: IoIosGitNetwork,
    title: "Social Service workers",
    content:
      "We train our social service staff to work with people who are experiencing distressing events in their lives. These workers help our clients to promote their rights and fulfil their responsibilities in ways that empower them.",
    backgroundImage:
      "https://images.pexels.com/photos/4098228/pexels-photo-4098228.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    icon: LiaConnectdevelop,
    title: "Essential services",
    content: "",
    backgroundImage:
      "https://images.pexels.com/photos/5212317/pexels-photo-5212317.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    icon: PiBaby,
    title: "Transitional Care Services",
    content: "",
    backgroundImage:
      "https://images.pexels.com/photos/3087286/pexels-photo-3087286.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    icon: FaPersonShelter,
    title: "Occupational Therapy",
    content:
      "We help individuals of all ages achieve independence and improve their quality of life through meaningful activities and occupations. Whether recovering from injury, managing a disability, or coping with the challenges of aging.",
    backgroundImage:
      "https://images.pexels.com/photos/176794/pexels-photo-176794.jpeg?auto=compress&cs=tinysrgb&w=1200",
  },
  // {
  //   icon: RxRocket,
  //   title: "Production",
  //   content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  //   backgroundImage:
  //     "https://images.pexels.com/photos/2246789/pexels-photo-2246789.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  // },
];
